@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

@media screen and (max-width: 600px) {
	footer {
		.mapContact {
			padding-top: 10px;
			margin-top: 30px !important;
			display: block !important;
			background: rgba(55,51,52,.9);
			margin-left: auto;
			float: none !important;
			margin-right: auto; }
		.mapContactForm {
			margin-top: 30px;
			float: none !important;
			display: block !important;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px; } } }

footer {
	font-family: 'Ubuntu', sans-serif !important;
	min-height: 100px;
	padding-bottom: 30px;
	background: #EDEDED;
	.footerMap {
		min-height: 400px;
		background: url(../images/mapa.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center; }
	.mapContact {
		display: inline-block;
		float: left;
		height: 235px;
		width: 280px;
		padding-top: 50px;
		margin-top: 90px;
		background: rgba(244, 129, 32, .9);
		h2 {
			color: white;
			font-size: 1.6em;
			margin-bottom: 25px;
			text-transform: none;
			margin-top: 0px; }
		a {
			margin-bottom: 0px;
			text-align: center;
			color: white;
			display: block;
			&:hover {
				color: white; } }
		a.newContacts {
			text-transform: uppercase;
			color: white;
			text-align: center;
			margin-top: 30px;
			display: block;
			font-weight: bold;
			letter-spacing: 0.5px; } }
	.mapContactForm {
		display: inline-block;
		float: right;
		background: #EDEDED;
		width: 300px;
		height: 400px;
		border-top: 6px solid #92003E;
		display: block;
		right: 40px;
		input {
			display: block;
			margin-left: auto;
			margin-right: auto;
			border: 1px solid $thinfontColor;
			padding: 10px 15px;
			margin-top: 10px;
			width: 190px; }
		h2 {
			font-size: 1.4em;
			font-weight: bold;
			margin-top: 10px;
			color: $boldfontColor; }
		textarea {
			border: 1px solid $thinfontColor;
			display: block;
			margin-left: auto;
			width: 190px;
			margin-right: auto;
			height: 130px;
			border-radius: 0;
			margin-top: 10px; }
		input.submitButton {
			width: 140px;
			font-weight: bold;
			height: 50px;
			transition: background 0.5s;
			font-size: 1.2em;
			background: #74A839;
			border: 2px solid #74A839;
			color: white;
			text-transform: uppercase;
			&:hover {
				background: white;
				color: #74A839; } } }
	.footerMenu {
		ul {
			li {
				padding: 0px !important; } } }
	.navbar-nav > li {
		float: none !important; }
	.navbar-nav {
		float: none !important; }

	.footerMenu {
		padding-top: 5px;
		min-height: 60px;
		border-bottom: 2px solid #DEDEDE;
		ul {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			display: block;
			list-style-type: none;
			li {
				display: inline-block;
				list-style-type: none;
				width: auto;
				color: $boldfontColor;
				padding: 15px 10px;
				text-transform: uppercase;
				font-weight: bold; } } }
	.upIconWrapper {
		padding-top: 90px;
		.upIcon {
			background: url(../svg/upicon.svg);
			width: 40px;
			height: 40px;
			display: block;
			&:hover {
				cursor: pointer; } } }
	.logo {
		img {
			width: 190px;
			margin-left: auto;
			margin-right: auto;
			display: block;
			padding-top: 20px; } }
	.mediaIcons {
		.row {
			margin-left: 0px !important;
			margin-right: 0px !important;
			.iconWrapper {
				padding-left: 0px !important;
				padding-right: 0px !important;
				padding-top: 90px; } }
		.facebookIcon {
			background: url(../svg/fbicon.svg);
			width: 40px;
			height: 40px;
			display: inline-block;
			float: right; }
		.youtubeIcon {
			background: url(../svg/youtubeicon.svg);
			width: 40px;
			height: 40px;
			margin-left: 20px;
			float: right;
			display: inline-block; } }
	.copyrightContent {
		padding-top: 25px;
		text-align: center;
		color: $thinfontColor;
		.bold {
			color: $boldfontColor; }
		.line {
			padding-left: 10px;
			padding-right: 10px; } }
	a {
		color: $thinfontColor;
		&:hover {
			color: $boldfontColor;
			cursor: pointer; } } }
@media screen and (max-width: 772px) {
	.facebookIcon, .youtubeIcon {
		float: none !important; }
	.youtubeIcon {
		margin-right: 10px; }
	.iconWrapper {
		padding-top: 30px !important;
		text-align: center; }
	.facebookIcon {
		margin-left: 20px; }
	.upIconWrapper {
		padding-top: 20px !important;
		.upIcon {
			margin-left: auto;
			margin-right: auto; } } }
