@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }

.alignCenter {
	text-align: center; }
.filterIdentificator {
	display: inline-block;
	width: 100px;
	font-size: 1.3em;
	font-weight: bold;
	text-transform: uppercase; }

.pagination {
	.current {
		padding: 3px !important;
		background: #cf142b !important; } }
.customFilterInput {
	padding: 15px 25px;
	color: white;
	background: #cf142b;
	border: 0px !important;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.3em; }
.customSelect {
	display: inline-block;
	width: 150px;
	padding: 10px 15px;
	margin-left: 10px;
	margin-right: 10px; }
.paginatorView {
	width: 100%;
	text-align: center; }
.searching {
	padding-bottom: 30px;
	font-weight: bold; }
.lightPadding {
	padding-top: 30px;
	padding-bottom: 30px; }
.productPicture {
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	height: 120px; }
.productHeader {
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	padding-top: 20px;
	min-height: 80px;
	color: #6d6d6d; }
.productDescription {
	color: #8a8a8a;
	font-size: 12px;
	text-align: center;
	span {
		font-size: 12px; } }
.productDetailButton {
	padding: 10px 20px;
	text-align: center;
	display: block;
	margin-left: auto;
	margin-right: auto;
	min-width: 110px;
	color: white;
	background: #cf142b;
	border: 1px solid #cf142b;
	text-transform: uppercase;
	margin-bottom: 30px;
	margin-top: 30px;
	font-weight: bold;
	&:hover {
		background: white;
		color: #cf142b;
		text-decoration: none !important; } }

@media screen and (max-width: 1240px) {
	.bannerButtons {
		max-width: 130px;
		font-size: 1.1em !important; } }

.fullslider {
	max-height: 590px; }
.slider {
	#vertical {
		max-height: 570px; } }
.bannerSlider {
	background: rgba(0,0,0,.8);
	color: white;
	padding: 20px;
	width: 300px;
	min-height: 300px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-top: 70px;
	h2 {
		font-size: 3.5em;
		text-align: left; }
	h5 {
		font-size: 2em;
		padding-top: 25px;
		text-align: left; } }
.bannerSliderContext {
	.noPadding {
		padding-left: 0px !important;
		padding-right: 0px !important; }
	padding-top: 75px;
	h1 {
		color: white;
		font-size: 3em;
		text-transform: uppercase;
		font-weight: bold; }
	h3 {
		text-transform: uppercase;
		font-size: 1.4em;
		color: #6d6d6d;
		margin-bottom: 20px; }
	.sliderMoreText {
		position: relative;
		top: 15px; }
	.bannerButtons {
		padding: 10px 25px;
		font-size: 1.5em;
		width: 170px;
		min-height: 40px;
		display: inline-block;
		text-transform: uppercase;
		color: white;
		font-weight: bold;
		text-align: center; }
	.shadowButton {
		margin-right: 20px;
		margin-left: 0px;
		background: #e6e6e6;
		color: black;
		&:hover {
			background: #231f20;
			color: #e6e6e6;
			text-decoration: none !important; } }
	.redButton {
		background: #cf142b;
		&:hover {
			background: white;
			color: #cf142b !important;
			text-decoration: none !important; } }
	.blackButton {
		background: #231f20;
		&:hover {
			background: white;
			color: #231f20 !important;
			text-decoration: none !important; } } }


.rowpadding {
	padding-top: 40px !important;
	padding-bottom: 60px !important; }

.menu_level_1 {
	li {
		a {
			padding: 10px 20px; } } }
.dropdown-menu {
	.sub-menu {
		left: 100%;
		position: absolute;
		top: 0;
		visibility: hidden;
		margin-top: -1px; } }
.dropdown-menu {
	li:hover {
		.sub-menu {}
		visibility: visible; } }

.dropdown-toggle:hover {
	.dropdown-menu {
		display: block !important; } }

body, html {
	font-family: Ubuntu, sans-serif !important; }
button, input, optgroup, select, textarea {
	font-family: Ubuntu, sans-serif !important; }
.navbar {
	.glyphicon {
		position: relative;
		top: 3px !important; } }
.navbar-toggle.toggleButton {
	margin-top: 50px;
	margin-right: 50px;
	p {
		font-weight: bold;
		font-size: 1.3em; }
	span {
		padding-left: 5px; } }
.navbar {
	li.active {
		color: #cf142b !important; } }
.row {
	margin-left: 0px !important;
	margin-right: 0px !important; }

.rowpadding {
	.customHeaderRed {
		margin-bottom: 40px; } }

@media (max-width: 800px) {
	.navbar-header {
		float: none; }
	.navbar-toggle {
		display: block; }


	.navbar-collapse {
		border-top: 1px solid transparent; }
	.navbar-collapse.collapse {
		display: none!important; }
	.navbar-nav {
		float: none!important;
		margin: 0px !important; }
	.navbar-nav>li {
		float: none; }
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px; }
	.navbar-text {
		float: none;
		margin: 15px 0; }
	.nav.navbar-nav.navbar-right {
		text-align: center;
		position: static !important; }
	.navbar {
		.logoLink {
			img {
				width: 200px;
				padding-top: 15px; } } }
	.navbar-collapse.collapse.in {
		display: block!important; }
	.collapsing {
		overflow-x: hidden !important; } }


@media (max-width: 800px) and (min-width: 601px) {
	.navbar-nav.navbar-center {
		padding-top: 20px !important;
		li {
			display: inline-block !important; } } }

@media (max-width: 600px) {
	.navbar-nav.navbar-center {
		padding-top: 20px !important;
		li {
			display: block !important; } }
	.navbar-nav {
		.navbarIcons {
			display: none; } }
	.navbar-toggle.toggleButton {
		margin-right: 10px; }
	.navbar {
		.logoLink {
			img {
				width: 180px; } } } }

@media (max-width: 750px) {
	.bannerRicoh {
		h2 {
			font-size: 1.1em; }
		.ricohIcon {
			width: 80px;
			position: relative;
			top: 3px; } } }

@media (max-width: 994px) {
	.tileWrapper {
		display: none !important; }
	.topProducts {
		.productDescription {
			text-align: center !important; }
		.productDetailButton {
			margin-bottom: 30px; } } }

body, html {
	margin-left: 0px !important;
	margin-right: 0px !important;
	width: 100%; }
.content-web {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 30px;
	font-size: 16px;
	font-family: 'Ubuntu' !important;
	img {
		max-width: 100%; } }


.sm-simple {
	border: 0 none;
	background: none;
	@include box-shadow('none'); }

@media (min-width: 768px) {
	#main-menu > ul > li {
		float: none;
		display: table-cell;
		width: 1%;
		text-align: center; } }


.main-nav {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1000;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	background-color: $tertiaryColor;
	&:after {
		clear: both;
		content: "\00a0";
		display: block;
		height: 0;
		font: 0px/0 serif;
		overflow: hidden; } }

.nav-brand {
	float: left;
	margin: 0;
	a {
		display: block;
		padding: 11px 11px 11px 20px;
		color: #555;
		font-size: 20px;
		font-weight: normal;
		line-height: 17px;
		text-decoration: none;
		height: 60px;
		img {
			height: 100%;
			width: auto; } } }
@media (min-width: 768px) {
	.nav-brand {
		a {
			height: 100px; } } }

#main-menu > ul {
	clear: both;
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	display: none;
	li {
		background-color: transparentize($tertiaryColor, 0.25); }
	> li > a {
		text-transform: uppercase; } }


@media (min-width: 768px) {
	.main-nav {
		position: absolute;
		background-color: transparent; }
	#main-menu > ul {
		float: right;
		clear: none;
		display: block;
		> li > a > .sub-arrow {
			display: none; } } }


/* Mobile menu toggle button */

.main-menu-btn {
	float: right;
	margin: 5px 10px;
	position: relative;
	display: inline-block;
	width: 29px;
	height: 29px;
	text-indent: 29px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
	position: absolute;
	top: 50%;
	left: 2px;
	height: 2px;
	width: 24px;
	background: #555;
	-webkit-transition: all 0.25s;
	transition: all 0.25s; }

.main-menu-btn-icon:before {
	content: '';
	top: -7px;
	left: 0; }

.main-menu-btn-icon:after {
	content: '';
	top: 7px;
	left: 0; }

/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
	height: 0;
	background: transparent; }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg); }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg); }

/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px); }

/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
	display: none; }

#main-menu-state:checked ~ #main-menu {
	display: block; }

@media (min-width: 768px) {
	/* hide the button in desktop view */
	.main-menu-btn {
		position: absolute;
		top: -99999px; }
	/* always show the menu in desktop view */
	#main-menu-state:not(:checked) ~ #main-menu {
		display: block; } }












ul#lang {
	list-style: none;
	margin: 0;
	*zoom: 1;
	li {
		float: left;
		visibility: collapse;
		a {
			display: block;
			padding: 3px 10px;
			visibility: visible; } }
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; }
	&.flag {
		a {
			width: 2rem;
			height: 1.5rem;
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat; } }
	&.code {
		a {
			&:before {
				content: attr(data-code);
				text-transform: uppercase; } } }
	&.name {
		a {
			&:before {
				content: attr(title); } } } }



.relative {
	position: relative; }

.align-center {
	text-align: center; }
.middle {
	@include verticalAlign(); }


.bold {
	font-weight: bold; }
.uppercase {
	text-transform: uppercase; }


/* line break after element */
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

/* clear-fix */
.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

@media screen and (max-width: 1024px) {
	.cf-medium {
		*zoom: 1;
		&:before,
		&:after {
			content: ' ';
			display: table; }
		&:after {
			clear: both; } } }
@media screen and (min-width: 1025px) {
	.small-0 {
		width: 0; } }


/* proportional height */
#testProportional {
	margin: 50px auto;
	width: 300px;
	background-color: green; }

.crust {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		padding-top: 100%; }
	.core {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		img,
		.content {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto; }
		.content {
			width: 100%;
			height: 100%; } }
	&.cover .core img {
		/*width: 100%*/
		/*height: auto*/
		/*max-height: none*/
		min-width: 100%;
		min-height: 100%;
		height: auto;
		max-width: none; }
	&.ratio-2_1:before {
		padding-top: 50%; }
	&.ratio-4_3:before {
		padding-top: 75%; }
	&.ratio-16_9:before {
		padding-top: 56.25%; }
	&.ratio-9_4:before {
		padding-top: 44.44%; }
	&.ratio-5_4:before {
		padding-top: 80%; }
	&.ratio-1_2:before {
		padding-top: 200%; }
	&.ratio-4_5:before {
		padding-top: 125%; } }

/* icon before content */
a.icon-prev {
	&:hover {
		color: lighten($primaryColor, 20); } }
.icon-prev {
	position: relative;
	display: inline;
	margin-left: 2.25em;
	white-space: nowrap;
	&:before {
		position: absolute;
		margin-top: 0.15em;
		left: -1.20em;
		font-size: 1.10em;
		color: $quaternaryColor; }
	&:first-child {
		margin-left: 1.35em; } }





/* title after icon */
.tai {
	position: relative;
	white-space: nowrap;
	color: $primaryColor;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin-right: $base-font-size;
	&:after {
		content: attr(data-title);
		position: relative;
		display: inline-block;
		font-size: $base-font-size * 0.75;
		line-height: $base-line-height;
		margin-left: $base-font-size * 0.33; }
	&:before {
		line-height: $base-line-height; } }


@media screen and (max-width: 991px) {

	.tai {
		cursor: help;
		font-size: $base-font-size * 2.5;
		&:after {
			display: none; }
		&:hover:after,
		&:focus:after,
		&:active:after {
			display: block;
			position: absolute;
			top: $base-line-height * 0.5;
			left: 100%;
			z-index: 1000;
			background-color: transparentize($tertiaryColor, 0.15);
			font-size: $base-font-size;
			line-height: $base-line-height;
			padding: 1px 4px;
			@include border-radius(2px); }
		&:before {
			line-height: $base-line-height * 2.5; } }
	a.tai {
		cursor: pointer; } }


.req-sign {
	color: $errorColor;
	font-weight: bold; }
label {
	.req::after {
		@extend .req-sign;
		content: '*';
		position: absolute;
		margin-left: 0.3rem {
	    margin-right: 1.2rem; } } }


ul.evenly {
	text-align: justify;
	min-width: 500px;
	&:after {
		content: '';
		display: inline-block;
		width: 100%; }
	li {
		display: inline-block; } }

.clickable {
	cursor: zoom-in; }


/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	//border: 1px dashed transparentize($warningColor, 0.75)
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }

.nav {
	li {
		a:hover {
			color: #cf142b !important;
			background: transparent !important; } } }
.navbar-nav {
	li {
		a {
			color: #6c6c6c;
			font-size: 1.1em;
			text-transform: uppercase;
			font-weight: bold; } }
	li.active {
			color: #cf142b !important; } }
.minLogo {
	width: 275px;
	margin-left: 20px;
	padding-top: 15px;
	padding-bottom: 20px;
	position: absolute; }
.navbar {
	min-height: 130px; }
.navbar-nav.navbar-right {
	margin-right: 0px !important;
	position: absolute;
	right: 0px;
	top: 20px; }
.navbar-nav.navbar-center {
	padding-top: 40px;
	width: 100%;
	text-align: center;
	li {
		float: none;
		display: inline-block; }
	.dropdown-menu {
		li {
			display: block !important; } } }
.nav-footer {
	padding-top: 20px !important;
	padding-bottom: 20px; }
.navbar {
	#search {
		display: inline-block;
		margin-right: 15px;
		.inputSubmitIcon:hover {
			cursor: pointer; }
		.searchedQuery {
			position: relative;
			top: -12px;
			margin-left: 20px;
			width: 224px;
			height: 40px;
			display: inline-block;
			font-size: 1.3em;
			border: 1px solid #e6e6e6; } } }
.navbarIcons {
	padding-top: 20px;
	display: inline-block;
	.facebookIcon {
		background: url(../svg/fbicon.svg);
		width: 40px;
		height: 40px;
		display: inline-block;
		float: right; }
	.youtubeIcon {
		background: url(../svg/youtubeicon.svg);
		width: 40px;
		height: 40px;
		margin-left: 20px;
		float: right;
		display: inline-block; } }
.inputSubmitIcon {
	background: url(../svg/lupaicon.svg);
	width: 40px;
	height: 40px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	top: 3px;
	display: inline-block; }
.navbar {
	margin-bottom: 0px;
	border-top: 8px solid #cf142b; }

.lSPrev, .lsNext {
	display: none; }

.lSAction {
	display: none; }
.lSSlideOuter {
	.lSPager.lSpg {
		position: relative !important;
		top: -310px;
		width: 50px;
		float: right;
		li {
			display: block;
			a {
				width: 20px !important;
				height: 20px !important;
				background-color: white !important;
				border: 5px solid white; } }
		li.active {
			a {
				background-color: #cf142b !important; } } } }
.bannerRicoh {
	.positionBannerRicoh {
		position: absolute;
		right: 0;
		left: 0;
		margin-left: -430px;
		margin-top: -4px; }
	width: 100%;
	MIN-height: 40px;
	text-align: center;
	background: #cf142b;
	padding-top: 8px;
	padding-bottom: 8px;
	.fa {
		display: inline-block;
		margin-left: 10px;
		margin-right: 10px;
		position: relative;
		top: 4px;
		color: white;
		font-size: 1.2em; }
	h2 {
		text-transform: uppercase;
		text-align: center;
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 1.3em;
		font-weight: bold;
		display: inline-block;
		top: 4px;
		color: white;
		position: relative; } }
.euroDidactPas {
	width: 100%;
	height: 80px;
	background: url(../images/eurodidact.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 30px;
	background-position: center; }
.ricohIcon {
	height: 30px;
	display: inline-block; }

.customSmallHeaderBlack {
	margin-top: 5px;
	text-transform: uppercase;
	font-weight: bold;
	color: black;
	text-align: center; }
.customHeaderRed {
	color: #cf142b;
	margin-bottom: 0px;
	text-transform: uppercase;
	margin-top: 10px;
	font-weight: bold;
	letter-spacing: 2px; }
.customHeaderImg {
	display: block;
	margin-left: auto;
	width: 80px;
	margin-right: auto;
	text-align: center; }
.customHeaderOrangeColor {
	color: #f58220; }
.customHeaderPurpleColor {
	color: #91013d; }
.customHeaderGreenColor {
	color: #74a839; }
.customSmallHeaderGrey {
	margin-top: 10px;
	text-transform: uppercase;
	font-weight: bold;
	color: #b1b1b1;
	text-align: center;
	margin-left: 10px;
	margin-right: 10px; }

p.requiredText {
	font-style: italic; }
.customFormHeader {
	text-transform: uppercase;
	font-weight: bold;
	color: #6c6c6c;
	font-size: 1.3em; }
.customTextArea {
	border: 2px solid #dfdfdf; }
.redSubmitButton {
	padding: 15px 25px;
	background: #cf142b;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
	font-size: 1.2em;
	border: 1px solid #cf142b;
	&:hover {
		background: white;
		color: #cf142b; } }
.paddingTop {
	padding-top: 40px; }

.vertical-align-helper {
	display: table;
	height: 100%;
	width: 100%; }
.vertical-align-center {
	display: table-cell;
	vertical-align: middle; }

.leftMenuWrapper {
	padding-left: 0px !important;
	padding-bottom: 20px; }
.breadcrumb {
	width: 100%; }
.breadcrumb {
	margin-bottom: 0px;
	a {
		color: black;
		&:hover {
			text-decoration: none !important;
			color: #cf142b; } }
	span {
		color: #cf142b; } }
.leftmenu {
	background: #f5f5f5;
	padding-top: 20px;
	padding-bottom: 20px;
	.menu.nested {
		margin-left: 0px !important;
		padding-left: 20px; }
	ul {
		li {
			list-style: none !important;
			a {
				color: black; }
			&:hover {
				text-decoration: none !important; } }
		li.active > a {
			color: #cf142b;
			font-weight: bold !important;
			background: transparent !important; } }
	.menu.vertical {
		display: none; }
	.menu_level_1 {
		li {
			a {
				padding: 5px 20px !important; } } } }

#load-products {
	margin-top: 25px; }

.tileCatalog {
	width: 200px;
	display: inline-block; }

@media screen and (max-width: 992px) {
	.leftMenuWrapper {
		padding-right: 0px; }
	#load-products {
		text-align: center; } }
@media screen and (max-width: 1250px) and (min-width: 1146px) {
	.minLogo {
		width: 240px !important; } }

@media screen and (max-width: 1145px) {
	.minLogo {
		width: 210px !important; } }
@media screen and (max-width: 1310px) {
	.navbar {
		#search {
			margin-top: 20px; } } }
@media screen and (max-width: 1300px) {
	.navbar {
		#search {
			.searchedQuery {
				width: 150px !important; } } } }
@media screen and (max-width: 1500px) {
	.navbar {
		#search {
			.searchedQuery {
				width: 180px !important; } } }
	.navbar-nav {
		li {
			a {
				padding-left: 5px !important;
				padding-right: 5px !important; } } } }



@media screen and (max-width: 1390px) and (min-width: 1121px) {
	.bannerSliderContext {
		h1 {
			font-size: 2.5em; }
		h3 {
			font-size: 1.1em; }
		.topProducts {
			.productTypes {
				h4 {
					font-size: 1em !important; } } }
		.bannerButtons {
			padding: 0px;
			span {
				top: 15px; } } }
	.fullslider {
		max-height: 430px;
		li {
			div {
				max-height: 430px; } } }
	.bannerSliderContext {
		padding-top: 40px; } }


@media screen and (max-width: 1200px) and (min-width: 1121px) {
	.productTypes {
		.offer {
			h4 {
				font-size: 1em !important; } } } }

@media screen and (max-width: 1120px) {
	.productTypes {
		.offer {
			h4 {
				font-size: 1em !important; } } }
	.bannerSliderContext {
		h1 {
			font-size: 2.5em; }
		h3 {
			font-size: 1.1em; }
		.bannerButtons {
			padding: 0px;
			span {
				top: 10px; } } }
	.fullslider {
		max-height: 390px;
		li {
			div {
				max-height: 390px; } } }
	.bannerSliderContext {
		padding-top: 40px; } }

@media screen and (max-width: 720px) {
	.positionBannerRicoh {
		display: inline-block;
		position: initial !important;
		margin-left: auto !important;
		margin-top: 0px; } }

@media screen and (max-width: 1145px) and (min-width: 800px) {
	.navbar {
		#search {
			.searchedQuery {
				width:  110px !important; } } }
	.minLogo {
		margin-top: 10px;
		width: 170px !important; } }


@media screen and (max-width: 1000px) and (min-width: 800px) {
	.minLogo {
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0; }
	.navbar {
		#search {
			display: none; } }
	.navbar {
		.navbar-nav.navbar-center {
			margin-top: 40px;
			margin-bottom: 20px; } } }

@media screen and (max-width: 1080px) {
	.navbarIcons {
		a {
			margin-left: 5px !important; } }
	.searchedQuery {
			margin-left: 5px !important; } }

@media screen and (max-width: 1030px) and (min-width: 800px) {
	.navbarIcons {
		display: none; } }
@media screen and (max-width: 600px) {
	.bannerSliderContext {
		h1 {
			font-size: 1.7em !important; } }
	.bannerSliderContext {
		h3 {
			display: none; } }
	.bannerSliderContext {
		.bannerButtons {
			display: none; } }
	.fullslider {
		li > div {
			height: 200px !important; } }
	.lSSlideOuter {
		height: 200px !important; }
	.lSSlideWrapper {
		height: 200px !important; }
	.lSSlideOuter {
		.lSPager.lSpg {
			top: -150px !important; } } }

@media screen and (max-width: 1000px) and (min-width: 601px) {
	.fullslider {
		li > div {
			height: 300px !important; } }
	.lSSlideOuter {
		height: 300px !important; }
	.lSSlideWrapper {
		height: 300px !important; }
	.lSSlideOuter {
		.lSPager.lSpg {
			top: -200px !important; } } }

@media screen and (min-width: 992px) and (max-width: 1165px) {
	.services {
		.pTag {
			min-height: 170px !important; } }
	.services {
		.servicesHeader {
			min-height: 90px !important; } } }
@media screen and (min-width: 1166px) and (max-width: 1243px) {
	.services {
		.pTag {
			min-height: 150px !important; } }
	.services {
		.servicesHeader {
			min-height: 90px !important; } } }

@media screen and (max-width: 991px) {
	.services {
		.servicesHeader {
			min-height: auto !important; } }
	.services {
		.pTag {
			min-height: auto !important; } } }
@import '_footer';

@import 'modules/_contact';
@import 'modules/eshop_detail';
@import 'modules/_home';
@import 'modules/_article';
